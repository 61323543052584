<template>
  <div>
    <div class="entitiesVersions">
      <div v-if="appliedOnDate || isCurationStale" class="spotlight-curation my-2">
        <div v-if="appliedOnDate" class="curation-applied">
          <i class="fas" :class="isCurationStale ? 'fa-minus-circle text-warning' : 'fa-check text-success'"></i>
          <b>Applied</b> to live spotlights on {{ appliedOnDate }} UTC
        </div>
        <div v-if="isCurationStale" class="curation-stale ml-3">
          <span v-if="appliedOnDate">* Note:</span> Recent curation changes have <b>not</b> yet been applied to live
          spotlights.
        </div>
      </div>
      <div
        v-if="_.get(spotlight, 'curation.curationType')"
        class="spotlight-status float-left mx-2"
        :class="{
          rejected: _.get(spotlight, 'curation.rejected'),
          approved: !_.get(spotlight, 'curation.rejected')
        }"
      >
        <i
          v-b-tooltip.hover
          class="far"
          :class="{
            'fa-eye-slash': _.get(spotlight, 'curation.rejected'),
            'fa-eye': !_.get(spotlight, 'curation.rejected')
          }"
          :title="`Spotlight ${_.capitalize(_.get(spotlight, 'curation.curationType'))}`"
        ></i>
      </div>
      <article-metadata :article="article" :spotlight-lens="lens" />
      <h5>Entity - Category Sentiment Matrix</h5>
      <div v-if="_.get(article, 'excluded')" class="overall-excluded">
        <span v-b-tooltip.hover title="entire article is excluded"><i class="fas fa-times-circle"></i></span> overall
      </div>
      <spotlight-polarity
        :current-pipeline="articleFilters?.pipeline || pipelines.v2"
        :article="article"
        :article-index="articleIndex"
        :spotlight="spotlight"
        :spotlight-id="spotlight.spotlightId"
        :spotlight-lens="lens"
        :category="category"
        :org-tvl-id="orgTvlId"
        :curation-changes="curationChanges"
      />
      <div v-if="!readOnly" class="spotlight-suppression my-4 text-center">
        <b-button
          variant="danger"
          class="mr-4 text-capitalize"
          @click="selectSuppressYes"
          >Suppress</b-button
        >
        <b-button
          variant="success"
          class="mr-4 text-capitalize"
          @click="selectSuppressNo"
          >Approve</b-button
        >
      </div>

      <!-- <div v-if="!readOnly" class="polarity-actions">
        <b-button
          variant="primary"
          :disabled="
            !submitEnabled || (articleFilters?.pipeline === pipelines.v2 && featureSwitches.disableSpotlightCuration)
          "
          class="mr-4 text-capitalize"
          @click="submitSpotlightPolarity"
          >submit</b-button
        >
        <b-button variant="secondary" :disabled="!isDirty" class="text-capitalize" @click="resetPolarity"
          >reset</b-button
        >
      </div> -->

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import spotlightPolarity from './spotlightPolarity'
import articleMetadata from './articleMetadata'
import xcomputed from '../../js/xcomputed'
import xconfig from '../../js/xconfig'
import xlibs from '../../js/xlibs'
// eslint-disable-next-line no-unused-vars
const { curatingFrom: _curatingFrom, ...xcomputedPart } = xcomputed
const { pipelines } = xconfig

export default {
  name: 'SpotlightsEntitiesSection',
  components: {
    spotlightPolarity,
    articleMetadata
  },
  props: {
    spotlight: {
      type: Object,
      default: null
    },
    articleIndex: {
      type: Number,
      default: null
    },
    spotlightLens: {
      type: String,
      default: null
    },
    curatingFrom: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      spotlightData: {
        spotlightId: _.get(this, 'spotlight.spotlightId'),
        lens: null,
        articleId: _.get(this, 'spotlight.displayArticle.articleId'),
        articlePubDateMs: _.get(this, 'spotlight.displayArticle.articlePubDateMs'),
        hourMs: _.get(this, 'spotlight.hourMs'),
        orgTvlId: _.get(this, 'spotlight.relatedTvlId', this.spotlight.orgTvlId),
        individualCategory: _.get(this, 'spotlight.individualCategory'),
        curatingFrom: this.curatingFrom,
        curationAction: null,
        changes: {}
      },
      initial: {
        scoreLevel: null,
        curationAction: null
      }
    }
  },
  computed: {
    ...xcomputedPart,
    ...mapGetters(['spotlights', 'articleFilters', 'spotlightChanges', 'spotlightCurationAction']),
    pipelines() {
      return pipelines
    },
    curationTypes() {
      return xconfig.curationTypes
    },
    readOnly() {
      if (this.curatingFrom === 'reports') return true
      return this.spotlightCurationLockCheck
    },
    articleId: function () {
      return _.get(this, 'spotlight.displayArticle.articleId')
    },
    spotlightChangePath: function () {
      return _.get(this, 'spotlight.spotlightId')
    },
    appliedOnDate: function () {
      const appliedOnMs = _.get(this, 'spotlight.appliedOnMs')
      if (appliedOnMs) {
        const appliedDate = moment.utc(appliedOnMs).format('ddd, MMM D YYYY, HH:mm:ss').toString()
        return appliedDate
      }
      return false
    },
    isCurationStale: function () {
      const appliedOnMs = _.get(this, 'spotlight.appliedOnMs', 0)
      const curatedMs = _.get(this, 'spotlight.curation.dateMs')
      const isCurationStale = curatedMs && curatedMs > appliedOnMs
      return isCurationStale
    },
    orgTvlId: function () {
      return _.get(this, 'spotlight.relatedTvlId', this.spotlight.orgTvlId)
    },
    curationChanges: function () {
      return _.get(this, 'spotlight.curation.changes', {})
    },
    category: function () {
      return _.get(this, 'spotlight.individualCategory')
    },
    lens() {
      let lensValue = _.get(this, 'spotlightLens', 'all')
      if (lensValue === 'all') {
        lensValue = _.get(this, 'spotlight.lens')
      }
      return lensValue
    },
    article: function () {
      let article = _.get(this, 'spotlight.article')
      if (!_.isEmpty(_.get(article, 'associationTags'))) {
        const associationTags = _.filter(article.associationTags, { tvlId: this.orgTvlId })
        article.dpv2 = { associationTags }
      }
      return article
    },
    isDirty: function () {
      let dirty = false
      let currentChanges = _.get(this, ['spotlightChanges', this.spotlightChangePath])
      const curatedLevel = _.get(this.curationChanges, 'curated.level')
      const currentCuratedLevel = _.get(currentChanges, 'curated.level')
      if (_.get(this.spotlightCurationAction, this.spotlight.spotlightId) !== this.initial.curationAction) {
        return true
      }
      if (!_.isNil(currentCuratedLevel) && currentCuratedLevel === curatedLevel) {
        return false
      }
      dirty = currentChanges

      return dirty
    },
    submitEnabled: function () {
      if (_.includes(_.toLower(this.curatingFrom), 'pending')) {
        return true
      }
      return this.isDirty
    }
  },
  mounted() {
    this.spotlightData.lens = this.lens
    const suppressedStatus = _.get(this, 'spotlight.curation.rejected')
      ? xconfig.curationTypes.suppressed
      : xconfig.curationTypes.approved
    _.set(this.initial, 'curationAction', suppressedStatus)
    this.$store.dispatch('setSpotlightCurationAction', {
      spotlightId: _.get(this, 'spotlight.spotlightId'),
      curationAction: suppressedStatus
    })
  },
  methods: {
    ...xlibs,
    clearSpotlightChanges() {
      const spotlightChangePath = this.spotlightChangePath
      this.$store.dispatch('clearSpotlightChange', { spotlightChangePath })
    },
    resetPolarity() {
      this.$store.dispatch('setSpotlightCurationAction', {
        spotlightId: _.get(this, 'spotlight.spotlightId'),
        curationAction: _.get(this.initial, 'curationAction')
      })
      this.clearSpotlightChanges()
    },
    setSuppressed() {
      this.$store.dispatch('setSpotlightCurationAction', {
        spotlightId: _.get(this, 'spotlight.spotlightId'),
        curationAction: xconfig.curationTypes.suppressed
      })
    },
    setApproved() {
      this.$store.dispatch('setSpotlightCurationAction', {
        spotlightId: _.get(this, 'spotlight.spotlightId'),
        curationAction: xconfig.curationTypes.approved
      })
    },
    selectSuppressYes() {
      this.clearSpotlightChanges()
      this.setSuppressed()
      this.submitSpotlightPolarity()
    },
    selectSuppressNo() {
      this.clearSpotlightChanges()
      this.setApproved()
      this.submitSpotlightPolarity()
    },
    async submitSpotlightPolarity() {
      const spotlightChanges = _.pick(this.spotlightChanges, this.spotlightData.spotlightId)
      if (!_.isEmpty(spotlightChanges)) {
        this.spotlightData.changes = spotlightChanges
      }
      this.spotlightData.curationAction = _.get(this.spotlightCurationAction, this.spotlightData.spotlightId)
      await xlibs.submitSpotlightCuration.call(this, this.spotlightData)
      _.set(this.spotlightData, 'changes', {})
      this.resetPolarity()
    }
  }
}
</script>
